import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Img from "gatsby-image";
import "../styles/single.scss";
import SocialShare from "../components/SocialShare";
import get from "lodash/get";
import { getParsedContent } from "../utils/contentParser";

// Create read estimate by counting the words
const readEstimate = (content) => {
  const minutes = Math.round(
    content.replace(/(<([^>]+)>)/gi, "").split(" ").length / 240,
  );
  return minutes ? minutes : 1;
};

export default class Post extends Component {
  render() {
    const { data } = this.props;
    const parsedContent = getParsedContent(data.wordpressPost.content);

    let authorImage = get(
      data,
      "wordpressPost.author.avatar_urls.wordpress_96.source_url",
      "https://secure.gravatar.com/avatar/0c9e8be466cd5ccb2cc6161219f453e8?s=96&d=mm&r=g",
    );
    authorImage = authorImage.replace(
      new RegExp(
        "(?<![-A-Za-z0-9.])" +
          (process.env.GATSBY_SITE_DOMAIN || "www.travelnursing.org"),
      ),
      process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org",
    );

    if (!data.wordpressPost.featured_media?.localFile) {
      console.log(
        `featured_media.localFile is missing on ${data.wordpressPost.yoast_meta.yoast_wpseo_canonical} - default image will be used`,
      );
    }

    return (
      <Layout templateName="post" relatedArticles={data.relatedArticles.edges}>
        <Seo
          title={data.wordpressPost.yoast_meta.yoast_wpseo_title}
          description={data.wordpressPost.yoast_meta.yoast_wpseo_metadesc}
          image={
            data.wordpressPost.featured_media?.localFile?.childImageSharp
              ?.original?.src
              ? `${
                  process.env.NODE_ENV === "production"
                    ? data.site.siteMetadata.baseUrl
                    : typeof window !== "undefined" && window.location.host
                }${
                  data.wordpressPost.featured_media.localFile.childImageSharp
                    .original.src
                }`
              : ""
          }
          canonical={data.wordpressPost.yoast_meta.yoast_wpseo_canonical}
          datePublished={data.wordpressPost.date}
          dateModified={data.wordpressPost.modified}
          schemaType="Article"
          author={data.wordpressPost.author}
        />
        <Img
          className="single__image"
          fluid={get(
            data,
            "wordpressPost.featured_media.localFile.childImageSharp.fluid",
            data.placeholderImage.childImageSharp.fluid,
          )}
        />
        <div className="single__meta">
          <div className="author">
            <div className="author__avatar">
              <img src={authorImage} alt={data.wordpressPost.author.name} />
            </div>
            <div className="author__meta">
              <strong>
                <Link to={`/${data.wordpressPost.author.slug}/`}>
                  {data.wordpressPost.author.name}
                </Link>
              </strong>
              <br />
              {data.wordpressPost.date}
              {" - "}
              {readEstimate(data.wordpressPost.content)} min read
            </div>
          </div>
          <SocialShare shareData={data.wordpressPost} />
        </div>
        <h1
          className="single__heading"
          dangerouslySetInnerHTML={{
            __html: data.wordpressPost.title,
          }}
        />
        <div
          className="single__content"
          dangerouslySetInnerHTML={{
            __html: parsedContent,
          }}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query PostQuery($slug: String!, $category: String!) {
    site {
      siteMetadata {
        baseUrl
      }
    }
    wordpressPost(slug: { eq: $slug }) {
      title
      content
      link
      slug
      modified
      categories {
        slug
      }
      date(formatString: "MMMM D, YYYY")
      author {
        name
        slug
        avatar_urls {
          wordpress_96 {
            source_url
          }
        }
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 820) {
              ...GatsbyImageSharpFluid_withWebp
            }
            original {
              src
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    placeholderImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 820) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    relatedArticles: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $category } } }
        slug: { ne: $slug }
      }
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM D, YYYY")
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
