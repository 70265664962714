import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaEnvelopeSquare,
} from "react-icons/fa";

const SocialShareWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
  a {
    display: inline-block;
  }
  svg {
    fill: #081a31;
    height: 26px;
    transition: fill 0.2s ease-out;
    width: 26px;

    &:hover {
      fill: #3ac0a4;
    }
  }
`;

const SocialShare = ({ shareData }) => {
  // Get the sites domain url from config
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          baseUrl
        }
      }
    }
  `);

  const handleSocialShare = (evt, target) => {
    evt.preventDefault();
    let windowSize;
    switch (target) {
      case "facebook":
        windowSize = "width=580,height=368";
        break;
      case "twitter":
        windowSize = "width=500,height=300";
        break;
      case "linkedin":
        windowSize = "width=370,height=450";
        break;
      default:
        break;
    }
    window.open(
      evt.currentTarget.href,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes," + windowSize,
    );
  };

  const shareUrl = site.siteMetadata.baseUrl + "/" + shareData.slug;

  return (
    <SocialShareWrapper>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
        title="Share on Facebook"
        rel="noopener noreferrer"
        onClick={(e) => handleSocialShare(e, "facebook")}
      >
        <FaFacebookSquare />
      </a>
      <a
        href={`https://www.twitter.com/intent/tweet?url=${shareUrl}`}
        title="Share on Twitter"
        rel="noopener noreferrer"
        onClick={(e) => handleSocialShare(e, "twitter")}
      >
        <FaTwitterSquare />
      </a>
      <a
        href={`mailto:?subject=${shareData.title}&body=${shareUrl}`}
        target="_blank"
        title="Share by email"
        rel="noopener noreferrer"
      >
        <FaEnvelopeSquare />
      </a>
    </SocialShareWrapper>
  );
};

export default SocialShare;
