export const getParsedContent = (originalContent) => {
  // Parse the content to remove any domain names, .php, and replace old forms
  return originalContent
    .replaceAll(
      new RegExp(
        `https://${process.env.GATSBY_SITE_DOMAIN || "www.travelnursing.org"}[/](?!wp)`,
        "g",
      ),
      "/",
    )
    .replaceAll(/mobile-travel-nursing.php/g, "mobile/")
    .replaceAll(/a.php/g, "sign-up-full/")
    .replaceAll(/[.]php/g, "/")
    .replaceAll(
      /src="\/wp-content/g,
      `src="https://${process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org"}/wp-content`,
    )
    .replaceAll(
      new RegExp(
        `(?<![-A-Za-z0-9.])${process.env.GATSBY_SITE_DOMAIN || "www.travelnursing.org"}/wp-content`,
        "g",
      ),
      `${process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org"}/wp-content`,
    );
};
